import { FC, useState } from "react";
import styled from "styled-components";

interface InputCheckboxProps {
  labelText?: string;
  isDisabled?: boolean | undefined;
}
export const InputCheckbox: FC<InputCheckboxProps> = ({
  labelText,
  isDisabled,
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const CheckboxWrapper = styled.div`
    margin: 30px 0;
    label {
      display: grid;
      grid-template-columns: auto auto;
      justify-content: flex-start;
    }
    cursor: pointer;
    input[type="checkbox"] {
      position: relative;
      -webkit-appearance: none;
      appearance: none;
      width: 20px;
      height: 20px;
      border-radius: 3px;
      margin-right: 15px;
      border: 2px solid #979797;
      outline: none;
      cursor: pointer;
      &.checked {
        background-color: #979797;
        position: relative;
      }
    }
    input[type="checkbox"]:disabled {
      border-color: #c0c0c0;
      background-color: #c0c0c0;
    }
    input[type="checkbox"]:disabled + span {
      color: #c0c0c0;
    }
    input[type="checkbox"]:focus {
      box-shadow: 0 0 20px #979797;
    }
  `;
  return (
    <CheckboxWrapper>
      <label>
        <input
          className={isChecked ? "checked" : ""}
          type="checkbox"
          disabled={isDisabled}
          checked={isChecked}
          onChange={() => setIsChecked((prev) => !prev)}
          {...props}
        />
        <span>{labelText}</span>
      </label>
    </CheckboxWrapper>
  );
};
export default InputCheckbox;
