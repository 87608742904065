import styled from 'styled-components';
import searchIcon from '../../../assets/icons/search-icon.svg';

interface SearchBoxProps {
  inputValue: string;
  onInputChange: (inputValue: string) => void;
}
interface InputProps {
  icon: string;
}

const Input = styled.input<InputProps>`
  border: none;
  border-radius: 8px;
  padding: 10px 10px 10px 43px;
  width: 100%;
  max-width: 285px;
  background: url(${(props) => props.icon}) no-repeat 15px center #fff;
  background-size: 18px 18px;
  line-height: 1.43;
  color: var(--secondary);
  font-weight: var(--text-medium);
  &:focus {
    outline: none;
  }
`;

export const SearchBox = ({ inputValue, onInputChange }: SearchBoxProps) => {
  return (
    <Input
      type="text"
      placeholder="Vorlage suchen..."
      icon={searchIcon}
      onChange={(e) => onInputChange(e.target.value)}
      value={inputValue}
    />
  );
};
