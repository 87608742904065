import styled from "styled-components";
import Star from "../assets/icons/star.svg";

interface RatingProps {
  count?: string;
}

const RatingList = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 15px;
  span {
    margin-left: 2px;
  }
`;
export const Rating = ({ count = "0" }: RatingProps) => {
  return (
    <RatingList>
      <img src={Star} />
      <img src={Star} />
      <img src={Star} />
      <img src={Star} />
      <img src={Star} />
      <span>{count}</span>
    </RatingList>
  );
};
