import { KonvaEventObject } from "konva/lib/Node";
import { useState, useEffect, useRef } from "react";
import { Image as KonvaImage, Group } from "react-konva";
import useImage from "use-image";
import { AddedSticker } from "../context/MediaCaptureContext";
import Hammer from "hammerjs";
export type Position = {
  x: number;
  y: number;
};
interface StoryStickerProps {
  sticker: AddedSticker;
  onDragEnd: (e: KonvaEventObject<DragEvent>) => void;
  onDragMove: (e: KonvaEventObject<DragEvent>) => void;
  onDragStart: () => void;
  handleSticker: (scale: number, height: number) => void;
}
export const StorySticker = ({
  sticker,
  onDragEnd,
  onDragStart,
  onDragMove,
  handleSticker,
}: StoryStickerProps) => {
  const imageRef = useRef<any>(null);
  const [stickerImage] = useImage(sticker.src);
  const [scale, setScale] = useState<number>(1);
  const [rotate, setRotate] = useState<number>(1);
  const [height, setHeight] = useState<number>(0);
  useEffect(() => {
    const stickerHeight = stickerImage
      ? (sticker.width * stickerImage.height) / stickerImage.width
      : 0;
    setHeight(stickerHeight);
  }, [sticker.width, stickerImage]);
  useEffect(() => {
    document.body.addEventListener("touchmove", function () {}, false);
    let stage = imageRef.current;
    if (!stage) return;
    let mc = new Hammer.Manager(stage);
    let pan = new Hammer.Pan();
    let rotate = new Hammer.Rotate();
    let pinch = new Hammer.Pinch();

    mc.add([pan, pinch, rotate]);
    mc.get("pinch").set({ enable: true });
    mc.get("rotate").set({ enable: true });

    let adjustScale = 1;
    let adjustRotation = 0;
    let currentScale: any = null;
    let currentRotation: any = null;

    mc.on("panstart pinchstart rotatestart", function (e) {
      adjustRotation -= e.rotation;
    });

    mc.on("panmove pinchmove rotatemove", function (e) {
      currentRotation = adjustRotation + e.rotation;
      currentScale = adjustScale * e.scale;
      setScale(currentScale);
      setRotate(currentRotation);
    });

    mc.on("panend pinchend rotateend", function (e) {
      adjustScale = currentScale;
      adjustRotation = currentRotation;
    });
  }, []);

  useEffect(() => {
    handleSticker(scale, height);
  }, [scale, height]);
  return (
    <Group
      draggable
      x={sticker.x}
      y={sticker.y}
      onDragEnd={(event) => {
        onDragEnd(event);
      }}
      onDragMove={(event) => {
        onDragMove(event);
      }}
      onDragStart={onDragStart}
    >
      <KonvaImage
        width={sticker.width}
        height={height}
        image={stickerImage}
        scaleX={scale}
        scaleY={scale}
        rotation={rotate}
        ref={imageRef}
        offsetX={(sticker.width * scale) / 50}
        offsetY={(height * scale) / 50}
      />
    </Group>
  );
};
