import { useEffect, useRef } from 'react';
import { useMediaCaptureContext } from '../hooks/useMediaCaptureContext';
import { useTouchEventDetection } from '../hooks/useTouchEventDetection';

export const RecordButton = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  let myReq: number;

  const { isInCaptureMode, setIsPhotoShot, setIsVideoRecording } =
    useMediaCaptureContext();

  const {
    action,
    resetTouchEventDetectionHookState,
    handlers: {
      onTouchStart: handleOnTouchStart,
      onTouchEnd: handleOnTouchEnd,
    },
  } = useTouchEventDetection();

  const renderRecordButton = () => {
    if (!canvasRef.current) return;
    const context = canvasRef.current.getContext('2d');
    const x = canvasRef.current.width / 2;
    const y = canvasRef.current.height / 2;
    if (!context) return;
    context.beginPath();
    context.arc(x, y, 32, 0, 2 * Math.PI);
    context.fillStyle = 'white';
    context.fill();
    context.beginPath();
    context.arc(x, y, 36, 0, 2 * Math.PI);
    context.strokeStyle = 'white';
    context.lineWidth = 4;
    context.stroke();
  };

  const handleTouchStart = (event: Event) => {
    handleOnTouchStart();

    // event.preventDefault();
    if (!canvasRef.current) return;
    const context = canvasRef.current.getContext('2d');
    const x = canvasRef.current.width / 2;
    const y = canvasRef.current.height / 2;
    const radius = 41;
    const endPercent = 500;
    let curPerc = 0;
    const circ = Math.PI * 2;
    const quart = Math.PI / 2;
    if (!context) return;
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    context.beginPath();
    context.arc(x, y, 35, 0, 2 * Math.PI);
    context.fillStyle = 'rgb(253, 45, 85)';
    context.fill();
    context.beginPath();
    context.arc(x, y, 12, 0, 2 * Math.PI);
    context.fillStyle = '#fff';
    context.fill();
    context.lineWidth = 5;
    context.beginPath();
    context.arc(x, y, 41, 0, 2 * Math.PI);
    context.strokeStyle = 'rgba(253, 45, 85, 0.3)';
    context.stroke();
    context.lineWidth = 5;
    context.strokeStyle = 'rgba(253, 45, 85, 0.1)';
    context.shadowOffsetX = 0;
    context.shadowOffsetY = 0;

    const animate = (current?: any) => {
      context.beginPath();
      context.strokeStyle = 'rgba(253, 45, 85, 0.1)';
      context.arc(x, y, radius, -quart, circ * current - quart, false);
      context.stroke();
      curPerc++;
      if (curPerc < endPercent) {
        myReq = requestAnimationFrame(function () {
          animate(curPerc / 300);
        });
      }
    };
    animate();
  };

  const handleTouchEnd = () => {
    handleOnTouchEnd();

    cancelAnimationFrame(myReq);
    if (!canvasRef.current) return;
    const context = canvasRef.current.getContext('2d');
    if (!context) return;
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
  };
  console.log(action);

  useEffect(() => {
    const cleanup = () => {
      let setTimeoutId: any;
      setTimeoutId = setTimeout(() => {
        resetTouchEventDetectionHookState();
        clearTimeout(setTimeoutId);
      }, 1500);
    };

    if (action === 'click') {
      setIsPhotoShot(true);
      cleanup();
    }
    if (action === 'longpress') {
      setIsVideoRecording(true);
    }
    if (action === 'longpressend') {
      setIsVideoRecording(false);
      cleanup();
    }
  }, [action]);

  useEffect(() => {
    renderRecordButton();
  }, [isInCaptureMode]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    canvas.addEventListener('touchstart', handleTouchStart, false);
    canvas.addEventListener('touchend', handleTouchEnd, false);

    return () => {
      if (!canvas) return;
      canvas.removeEventListener('touchstart', handleTouchStart, false);
      canvas.removeEventListener('touchend', handleTouchEnd, false);
    };
  }, []);

  return (
    <div>
      <canvas ref={canvasRef} width="90" height="90" id="viewport"></canvas>
    </div>
  );
};
