import styled from 'styled-components';

interface TabProps {
  id: number;
  stickersCategory: string;
  activeTab: number;
  onTabClick: (clickedTab: number) => void;
}

interface PProps {
  isActive: boolean;
}

const P = styled.p<PProps>`
  font-weight: var(--text-bolder);
  color: ${({ isActive }) =>
    isActive ? 'rgb(var(--primary))' : 'var(--secondary)'};
  border-bottom: ${(props) =>
    props.isActive && '2px solid rgb(var(--clr-secondary))'};
  padding-bottom: 8px;
  cursor: pointer;
  &:not(:last-of-type) {
    margin-right: 25px;
  }
`;

export const Tab = ({
  id,
  stickersCategory,
  activeTab,
  onTabClick,
}: TabProps) => {
  const isActive = id === activeTab;

  return (
    <P isActive={isActive} onClick={() => onTabClick(id)}>
      {stickersCategory}
    </P>
  );
};
