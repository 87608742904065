import { ReactNode } from 'react';
import styled from 'styled-components/macro';

type HeadingTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
type HeadingSize = 's' | 'm' | 'l' | 'xl';
type HeadingWeight = 'normal' | 'bolder' | 'bold';
type HeadingColor = 'primary' | 'secondary';
type HeadingAlign = 'left' | 'center' | 'right';

interface HeadingProps {
  tag?: HeadingTag;
  size?: HeadingSize;
  weight?: HeadingWeight;
  color?: HeadingColor;
  textAlign?: HeadingAlign;
  children: ReactNode;
  className?: string;
}

const StyledHeading = styled.h2<HeadingProps>`
  font-size: ${(props) => `var(--heading-${props.size})`};
  font-weight: ${(props) => props.weight};
  color: ${(props) => `var(--${props.color})`};
  text-align: ${(props) => props.textAlign};
  margin-bottom: 20px;
  line-height: 1;
`;

export const Heading = ({
  tag = 'h2',
  size = 'm',
  weight = 'bolder',
  textAlign = 'left',
  color = 'primary',
  children,
  className,
}: HeadingProps) => (
  <StyledHeading
    as={tag}
    size={size}
    weight={weight}
    textAlign={textAlign}
    color={color}
    className={className}
  >
    {children}
  </StyledHeading>
);
