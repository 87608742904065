import { FC } from 'react';
import styled from 'styled-components';
import { Paragraph } from '../Paragraph';
import calendarIcon from '../../assets/icons/calendar.svg';

interface InputDateProps {
  labelText: string;
  value: string;
  onInputDataChange: (value: string) => void;
  captionText: string;
}

interface StyledInputDateProps {
  icon: string;
}

const StyledLabel = styled.label`
  display: block;
  color: rgb(var(--clr-dark));
  font-size: var(--text-s);
  margin-bottom: 7px;
`;

const StyledInputDate = styled.input<StyledInputDateProps>`
  position: relative;
  border: solid 1px #bcbfc1;
  border-radius: 10px;
  padding: 15px;
  font-size: var(--text-m);
  color: rgb(var(--clr-dark));
  width: 100%;
  max-width: 335px;
  margin-bottom: 11px;
  outline: none;
  appearance: none;
  background: url(${({ icon }) => icon}) no-repeat right 15px center;
  &::-webkit-calendar-picker-indicator {
    z-index: 1;
    opacity: 0;
  }
`;

const StyledCaption = styled(({ className, children }) => (
  <Paragraph className={className} size="xs" color="secondary" weight="bolder">
    {children}
  </Paragraph>
))`
  margin-bottom: 40px;
`;

export const InputDate: FC<InputDateProps> = ({
  labelText,
  value,
  onInputDataChange,
  captionText,
}) => {
  const date = new Date(Date.now());
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const year = date.getFullYear();
  const formattedDate = `${year}-${month}-${day}`;

  return (
    <>
      <StyledLabel htmlFor="input">{labelText}</StyledLabel>
      <StyledInputDate
        icon={calendarIcon}
        type="date"
        required
        value={value || formattedDate}
        onChange={(e) => onInputDataChange(e.target.value)}
      />
      <StyledCaption>{captionText}</StyledCaption>
    </>
  );
};
