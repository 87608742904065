import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import checkmarkIcon from '../assets/icons/checkmark.svg';

interface ListProps {
  isWithIcon?: boolean;
  className: string;
  children: ReactNode;
}

interface StyledListProps {
  isWithIcon?: boolean;
  icon: string;
}

const StyledList = styled.ul<StyledListProps>`
  list-style-image: ${({ isWithIcon, icon }) => isWithIcon && `url(${icon})`};
  list-style: ${({ isWithIcon }) => !isWithIcon && 'none'};
  padding-left: ${({ isWithIcon }) => (isWithIcon ? '20px' : '0')};
  margin: 0;
`;

export const List: FC<ListProps> = ({
  isWithIcon = false,
  className,
  children,
}) => {
  return (
    <StyledList
      className={className}
      isWithIcon={isWithIcon}
      icon={checkmarkIcon}
    >
      {children}
    </StyledList>
  );
};
