import styled from 'styled-components';
import { Navbar } from '../components/Navbar';
import { Accordion } from '../components/Accordion';
import { Paragraph } from '../components/Paragraph';
import productImg from '../assets/img/product1.jpg';
import { Heading } from '../components/Heading';
import trashIcon from '../assets/icons/delete-icon.svg';
import { Summary } from '../components/Order/Summary';
import { Button } from '../components/Button';
import { useNavigate } from 'react-router-dom';
import { RadioItem } from '../components/Radioitem';
import PayPalLogo from '../assets/img/logo-paypal.png';
import KreditkarteLogo from '../assets/img/logo-visa-mastercard.png';
import TwintLogo from '../assets/img/logo-twint.png';
import SoforLogo from '../assets/img/logo-sofortu-berweisung.png';
import VorkasseLogo from '../assets/img/logo-vorauskasse.png';
import InputCheckbox from '../components/Inputs/InputCheckbox';
import { useState } from 'react';

export const Checkout = () => {
  const Checkout = styled.section`
    background-color: #f2f2f3;
    display: inline-block;
    width: 100%;
  `;
  const Container = styled.div`
    margin: 10px 0;
    background: #fff;
    padding: 30px 0px;
  `;
  const Wrapper = styled.div`
    padding: 0 20px;
  `;
  const Separator = styled.div`
    width: 100%;
    height: 1px;
    border-top: solid 1px #bcbfc1;
    opacity: 0.6;
    margin: 20px 0px;
  `;
  const SingleProduct = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: auto auto 40px;
    width: 100%;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
    margin: 5px 0px;
  `;
  const SummaryContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  `;
  const Price = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    p {
      padding-right: 13px;
    }
  `;
  const CloseIcon = styled.div`
    position: absolute;
    top: 0;
    right: 0;
  `;
  const ProductGallery = styled.div`
    position: relative;
    border-radius: 20px;
    padding: 10px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-height: 200px;
    max-width: 100px;
    z-index: 1;
    &:before {
      position: absolute;
      inset: 0;
      content: '';
      width: 100%;
      height: 100%;
      background-color: #f2f2f2;
      z-index: -1;
    }
    img {
      width: 100%;
      height: auto;
      max-height: 200px;
      object-fit: contain;
    }
    p {
      position: absolute;
      bottom: 10px;
      right: 15px;
    }
  `;

  const navigate = useNavigate();
  const [activePaymentMethod, setActivePaymentMethod] = useState(Number);

  return (
    <>
      <Navbar />
      <Checkout>
        <Container>
          <Wrapper>
            <Heading>Zusammenfassung</Heading>
            <Separator />
            <Accordion startState={true} title="Warenkorb (1 Artikel)">
              <SingleProduct>
                <ProductGallery>
                  <img src={productImg} alt="Product Thumbnail" />
                </ProductGallery>
                <SummaryContent>
                  <>
                    <Paragraph size="s" color="primary" weight="bold">
                      Bose QuietComfort 35 II
                    </Paragraph>
                    <Paragraph size="s" color="primary" weight="regular">
                      Kopfhörer
                    </Paragraph>
                  </>
                  <Price>
                    <Paragraph size="l" weight="regular">
                      CHF 199.00
                    </Paragraph>
                  </Price>
                </SummaryContent>
                <CloseIcon>
                  <img src={trashIcon} alt="Delete Icon" />
                </CloseIcon>
              </SingleProduct>
            </Accordion>
            <Separator />
            <Accordion title={'Vorschau Story'}>Lorem ipsum</Accordion>
            <Separator />
            <Accordion title={'Persönliche Grussbotschaft'}>
              Lorem ipsum
            </Accordion>
            <Separator />
            <Accordion title={'Versand'}>Lorem ipsum</Accordion>
            <Summary />
          </Wrapper>
        </Container>
        <Container>
          <Wrapper>
            <Heading>Zahlungsart wählen</Heading>
            <Separator />
            {PaymentsMethods.map((payment, index) => (
              <>
                <RadioItem
                  key={index}
                  onButtonClick={() => setActivePaymentMethod(payment.id)}
                  title={payment.name}
                  isActive={activePaymentMethod === payment.id ? true : false}
                >
                  <img src={payment.img} alt={payment.name} />
                </RadioItem>
                <Separator />
              </>
            ))}
          </Wrapper>
        </Container>
        <Container>
          <Wrapper>
            <InputCheckbox labelText="ABG Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua." />
            <Button
              buttonType="text"
              mode="primary"
              onBtnClick={() => {
                navigate('/success');
              }}
            >
              Jetzt sicher bezahlen
            </Button>
          </Wrapper>
        </Container>
      </Checkout>
    </>
  );
};

const PaymentsMethods = [
  { id: 1, name: 'PayPal', img: PayPalLogo },
  { id: 2, name: 'Kreditkarte', img: KreditkarteLogo },
  { id: 3, name: 'Twint', img: TwintLogo },
  { id: 4, name: 'Sofort', img: SoforLogo },
  { id: 5, name: 'Vorkasse', img: VorkasseLogo },
];
