import { useEffect } from 'react';

export const useLimitToOneTab = () => {
  useEffect(() => {
    const tabOpened = window.open('', '_self');
    window.onload = function () {
      if (localStorage.getItem('tab') === '1') {
        console.log(true);

        tabOpened!.close();
      } else {
        localStorage.setItem('tab', '1');
      }
    };

    window.onbeforeunload = function () {
      localStorage.setItem('tab', '0');
    };
  }, []);
};
