import { MouseEventHandler } from "react";
import styled from "styled-components";
import { capitalizeFirstLetter } from "../../../utils";

interface StickerProps {
  id: number;
  src: string;
  tags: string[];
  categoriesId: number[];
  onClick?: MouseEventHandler;
}

const Img = styled.img`
  display: block;
  width: 100%;
  max-width: max-content;
`;

export const Sticker = ({
  id,
  src,
  tags,
  categoriesId,
  onClick,
}: StickerProps) => {
  return (
    <Img
      src={src}
      alt={`${capitalizeFirstLetter(tags.join(", "))} sticker`}
      onClick={onClick}
    />
  );
};
