import { Link } from 'react-router-dom';
import styled from 'styled-components';
import search from '../assets/icons/search-icon.svg';
import iconMenu from '../assets/icons/icon-menu.svg';
import { FC } from 'react';
import { Logo } from './Icons/Logo';

interface NavbarProps {
  className?: string;
}

const Header = styled.header`
  display: flex;
  padding: 15px 20px;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(var(--clr-light));
`;
const MenuList = styled.ul`
  display: flex;
  list-style-type: none;
  li {
    padding-left: 20px;
  }
`;
export const Navbar: FC<NavbarProps> = ({ className }) => {
  return (
    <Header className={className}>
      <Link to="/">
        <Logo />
      </Link>
      <nav>
        <MenuList>
          <li>
            <img src={search} alt="Search icon" />
          </li>
          <li>
            <img src={iconMenu} alt="Menu icon" />
          </li>
        </MenuList>
      </nav>
    </Header>
  );
};
