import { Context, RefObject } from "react";

export const capitalizeFirstLetter = (string: string): string =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const flipCanvasCtx = (
  ctxRef: RefObject<CanvasRenderingContext2D>,
  canvasDimensions: { width: number; height: number }
) => {
  (ctxRef.current as CanvasRenderingContext2D).translate(
    canvasDimensions.width / 2,
    canvasDimensions.width / 2
  );
  (ctxRef.current as CanvasRenderingContext2D).scale(-1, 1);
  (ctxRef.current as CanvasRenderingContext2D).translate(
    -(canvasDimensions.width / 2),
    -(canvasDimensions.width / 2)
  );
};

export const clearCanvasCtx = (
  ctxRef: RefObject<CanvasRenderingContext2D>,
  canvasDimensions: { width: number; height: number }
) => {
  (ctxRef.current as CanvasRenderingContext2D).clearRect(
    0,
    0,
    canvasDimensions.width,
    canvasDimensions.height
  );
};

export const slugify = (string: string) => {
  string = string.toLowerCase().trim();
  const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
  const to = "aaaaaeeeeeiiiiooooouuuunc------";

  from.split("").forEach((_, i) => {
    string = string.replace(from[i], to[i]);
  });

  return string
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
};

const camelCased = (string: string) =>
  string.replace(/-([a-z])/g, (g: string) => g[1].toUpperCase());
camelCased("a-s");

const importAll = (r: any) => {
  const images = {} as { [key: string]: any };
  r.keys().forEach((key: string) => {
    const regexExec = /\/([a-z0-9-]+)\.(?:webp|png|jpe?g|svg)/g.exec(key);
    if (!regexExec) return;
    const camelCasedKey = camelCased(regexExec[1]);
    images[camelCasedKey] = r(key);
  });
  return images;
};

export const icons = importAll(
  require.context("./assets/icons", true, /\.(webp|png|jpe?g|svg)$/)
);
export const img = importAll(
  require.context("./assets/img", true, /\.(webp|png|jpe?g|svg)$/)
);
