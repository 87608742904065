import { ReactNode, useState } from 'react';
import styled from 'styled-components';
import { Paragraph } from './Paragraph';
import arrowDown from '../assets/icons/arrow-down.svg';
interface AccordionProps {
  startState?: Boolean;
  title: string;
  children?: ReactNode;
}
const AccordionItem = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;
const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const AccordionContent = styled.div`
  margin: 20px 0px;
`;
const AccordionIcon = styled.img`
  &.active {
    transform: rotate(180deg);
  }
`;
export const Accordion = ({
  startState = false,
  title,
  children,
}: AccordionProps) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(startState);
  const toggleAccordion = () => {
    let accordionState = isAccordionOpen;
    setIsAccordionOpen((accordionState = !(accordionState !== false)));
  };
  return (
    <AccordionItem>
      <AccordionHeader onClick={toggleAccordion}>
        <Paragraph size="m" weight="bold">
          {title}{' '}
        </Paragraph>
        <AccordionIcon
          className={`${isAccordionOpen ? 'active' : ''}`}
          src={arrowDown}
          alt="Arrow down"
        />
      </AccordionHeader>
      {isAccordionOpen && <AccordionContent>{children}</AccordionContent>}
    </AccordionItem>
  );
};
