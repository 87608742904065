import { FC, useEffect, useRef } from 'react';
import { useWindowSize } from 'react-use';
import styled from 'styled-components';
import { useMediaCaptureContext } from '../hooks/useMediaCaptureContext';

const StyledBlurContainer = styled.div`
  position: relative;
  width: 100%;
  height: 260px;
  border-radius: 25px;
  overflow: hidden;
  margin-bottom: 40px;
`;
const StyledStoryImgBlurBackground = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(20px);
`;
const StyledStoryImgPreview = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
const StyledStoryVideoBlurBackground = styled.canvas`
  filter: blur(20px);
`;
const StyledStoryVideoPreview = styled.video`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const WishesMediaPreview: FC = () => {
  const { renderedStoryImgPreview, renderedStoryVideoPreview } =
    useMediaCaptureContext();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const animationFrameIdRef = useRef<number>(0);
  const { width: windowWidth } = useWindowSize();

  const renderVideoOnCanvas = (
    ctx: CanvasRenderingContext2D,
    video: HTMLVideoElement,
    canvasWidth: number,
    canvasHeight: number
  ) => {
    ctx.drawImage(video, 0, 0, canvasWidth, canvasHeight);
    animationFrameIdRef.current = requestAnimationFrame(() =>
      renderVideoOnCanvas(ctx, video, canvasWidth, canvasHeight)
    );
  };

  useEffect(() => {
    if (!renderedStoryVideoPreview) return;
    const canvas = canvasRef.current as HTMLCanvasElement;
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    const video = videoRef.current as HTMLVideoElement;

    canvas.width = windowWidth;
    canvas.height = 260;

    renderVideoOnCanvas(ctx, video, canvas.width, canvas.height);

    return () => cancelAnimationFrame(animationFrameIdRef.current);
  }, [canvasRef.current]);

  return (
    <StyledBlurContainer>
      {renderedStoryImgPreview && (
        <>
          <StyledStoryImgBlurBackground src={renderedStoryImgPreview} />
          <StyledStoryImgPreview src={renderedStoryImgPreview} />
        </>
      )}
      {renderedStoryVideoPreview && (
        <>
          <StyledStoryVideoBlurBackground ref={canvasRef} />
          <StyledStoryVideoPreview
            ref={videoRef}
            src={renderedStoryVideoPreview}
            autoPlay
            playsInline
            loop
            muted
            controls={false}
          />
        </>
      )}
    </StyledBlurContainer>
  );
};
