import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import productImg from '../assets/img/product1.jpg';
import { Accordion } from '../components/Accordion';
import { Button } from '../components/Button';
import { Navbar } from '../components/Navbar';
import { Paragraph } from '../components/Paragraph';
import { Rating } from '../components/Rating';

export const Product = () => {
  const Container = styled.div`
    padding: 0 20px;
    margin-bottom: 35px;
  `;
  const Separator = styled.div`
    width: 100%;
    height: 1px;
    border-top: solid 1px #bcbfc1;
    opacity: 0.6;
    margin: 20px 0px;
  `;
  const SummaryContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 40px;
  `;
  const Price = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    p {
      padding-right: 13px;
    }
  `;
  const ProductGallery = styled.div`
    position: relative;
    border-radius: 20px;
    padding: 30px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    width: 100%;
    &:before {
      position: absolute;
      inset: 0;
      content: '';
      width: 100%;
      height: 100%;
      background-color: rgb(0, 0, 0);
      opacity: 0.05;
      z-index: -1;
    }
    img {
      width: 100%;
      height: auto;
      max-height: 500px;
      object-fit: contain;
    }
    p {
      position: absolute;
      bottom: 10px;
      right: 15px;
    }
  `;

  const navigate = useNavigate();

  return (
    <>
      <Navbar />
      <Container>
        <ProductGallery>
          <img src={productImg} alt="Product Thumbnail" />
          <Paragraph size="m">1/5</Paragraph>
        </ProductGallery>
        <SummaryContent>
          <Paragraph size="s" color="secondary" weight="bolder">
            Kopfhörer
          </Paragraph>
          <Paragraph size="m" weight="bolder">
            Bose QuietComfort 35 II
          </Paragraph>
          <Price>
            <Paragraph size="m" weight="regular">
              CHF 199.–
            </Paragraph>
            <Paragraph size="xs" color="secondary" weight="regular">
              inkl. MwSt.
            </Paragraph>
          </Price>
          <Rating />
        </SummaryContent>
        <Separator />
        <Accordion title="Beschreibung">Lorem ipsum</Accordion>
        <Separator />
        <Accordion title="Spezifikationen">Lorem ipsum</Accordion>
        <Separator />
        <Accordion title="Rückgabe & Garantie">Lorem ipsum</Accordion>
        <Separator />
        <Button
          buttonType="text"
          mode="primary"
          onBtnClick={() => {
            navigate('/story');
          }}
        >
          Mit Story verschenken
        </Button>
      </Container>
    </>
  );
};
