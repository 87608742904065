import { Splide, SplideSlide } from '@splidejs/react-splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

import styled from 'styled-components';
import '@splidejs/react-splide/css/core';

import { Paragraph } from './Paragraph';
interface ItemsCarouselProps {
  title?: string;
  items?: any;
}

const Wrapper = styled.div`
  padding: 50px 20px;
  li {
    margin: 0px 20px;
  }
  p {
    margin-bottom: 30px;
  }
`;
const SliderContainer = styled.div`
  img {
    height: 100%;
  }
`;
export const ItemsCarousel = ({ title, items }: ItemsCarouselProps) => {
  return (
    <Wrapper>
      <Paragraph weight="medium" size="s" textAlign="center" color="secondary">
        {title}
      </Paragraph>
      <SliderContainer>
        <Splide
          options={{
            type: 'loop',
            height: '10rem',
            focus: 'center',
            autoWidth: true,
            arrows: false,
            fixedHeight: '35px',
          }}
          extensions={{ AutoScroll }}
        >
          {items?.map((item: { src: string | undefined }) => (
            <SplideSlide>
              <img src={item.src} alt="Partner logo" />
            </SplideSlide>
          ))}
        </Splide>
      </SliderContainer>
    </Wrapper>
  );
};
