import { ReactNode, useState } from "react";
import styled from "styled-components";
import { Paragraph } from "./Paragraph";

interface RadioItemProps {
  title?: string;
  children?: ReactNode;
  isActive: Boolean;
  onButtonClick: () => void;
}

const RadioitemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;
const RadioItemHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
const RadioItemContent = styled.div`
  display: flex;
  align-items: center;
`;
const RadioCircle = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: solid 2px #bcbfc1;
  &.active {
    &:before {
      position: absolute;
      content: "";
      border-radius: 50%;
      top: 3px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 13px;
      height: 13px;
      background: #000;
    }
  }
`;

export const RadioItem = ({
  title,
  children,
  isActive,
  onButtonClick,
}: RadioItemProps) => {
  return (
    <RadioitemContainer onClick={onButtonClick}>
      <RadioItemHeader>
        <RadioCircle className={`${isActive ? "active" : ""}`} />
        <Paragraph size="s" weight="bolder">
          {title}
        </Paragraph>
      </RadioItemHeader>
      <RadioItemContent>{children}</RadioItemContent>
    </RadioitemContainer>
  );
};
