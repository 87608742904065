import styled from 'styled-components';
import { stickersCategories } from '../../../data/stickers';
import { Tab } from './Tab';

interface TabsProps {
  activeTab: number;
  onTabClick: (clickedTab: number) => void;
}

const TabsContainer = styled.div.attrs((props) => ({
  className: props.className,
}))`
  display: flex;
  overflow-x: auto;
  margin-bottom: 44px;
`;

export const Tabs = ({ activeTab, onTabClick }: TabsProps) => {
  return (
    <TabsContainer className='hideScrollbar'>
      {stickersCategories.map(({ id, name }) => (
        <Tab
          key={id}
          id={id}
          stickersCategory={name}
          activeTab={activeTab}
          onTabClick={(clickedTab) => onTabClick(clickedTab)}
        />
      ))}
    </TabsContainer>
  );
};
