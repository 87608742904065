import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { GlobalScript } from './components/GlobalScript';
import { MediaCaptureContextProvider } from './context/MediaCaptureContext';
import reportWebVitals from './reportWebVitals';
import { GlobalStyle } from './styles/GlobalStyle';
import { Checkout } from './views/Checkout';
import { Home } from './views/Home';
import { Product } from './views/Product';
import { Story } from './views/Story';
import { Success } from './views/Success';
import { Wishes } from './views/Wishes';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  // <React.StrictMode>
  <>
    <GlobalStyle />
    <MediaCaptureContextProvider>
      <BrowserRouter>
        <GlobalScript />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="product" element={<Product />} />
          <Route path="story" element={<Story />} />
          <Route path="wishes" element={<Wishes />} />
          <Route path="checkout" element={<Checkout />} />
          <Route path="success" element={<Success />} />
        </Routes>
      </BrowserRouter>
    </MediaCaptureContextProvider>
  </>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
