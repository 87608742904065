import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useIsFirstRender } from '../hooks/useIsFirstRender';
import { useMediaCaptureContext } from '../hooks/useMediaCaptureContext';
import { usePrevious } from '../hooks/usePrevious';
import { mediaDevicesPolyfill } from '../mediaDevicesPolyfill';
import { clearCanvasCtx, flipCanvasCtx } from '../utils';
import { StickersContainer } from './StickersContainer';
interface MediaCaptureProps {
  isFrontCamera: boolean;
  renderMedia: boolean;
}

interface CanvasProps {
  isFrontCamera: boolean;
}

interface VideoProps {
  isFrontCamera: boolean;
}

export interface Dimensions {
  width: number;
  height: number;
}

interface Contraints {
  audio: boolean;
  video: {
    facingMode: string;
    frameRate: {
      ideal: number;
    };
    aspectRatio: number;
  };
}

const Video = styled.video<VideoProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 112px);
  transform: ${(props) => (props.isFrontCamera ? 'scaleX(-1)' : 'scaleX(1)')};
  border-radius: 25px;
`;

const Canvas = styled.canvas<CanvasProps>`
  display: block;
  position: fixed;
  inset: 0;
  bottom: 100px;
`;

export const MediaCapture = ({
  isFrontCamera,
  renderMedia,
}: MediaCaptureProps) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const audioRef = useRef<MediaStreamTrack | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const chunksRef = useRef<Blob[]>([]);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const ctxRef = useRef<CanvasRenderingContext2D | null>(null);
  const requestPhotoId = useRef<number | null>(null);
  const requestVideoId = useRef<number | null>(null);
  const canvasDimensionsRef = useRef({
    width: window.innerWidth,
    height: window.innerHeight - 112,
  });
  const {
    isPhotoShot,
    setIsPhotoShot,
    isVideoRecord,
    isVideoRecording,
    setIsVideoRecord,
    isInCaptureMode,
    setIsInCaptureMode,
    addedStickers,
    setAddedStickers,
    setRenderedStoryImgPreview,
    setRenderedStoryVideoPreview,
  } = useMediaCaptureContext();
  const isFirstRender = useIsFirstRender();
  const isFrontCameraPrev = usePrevious(isFrontCamera);

  mediaDevicesPolyfill();

  const getMedia = async () => {
    // let stream: MediaStream;
    let stream: MediaStream = await navigator.mediaDevices.getUserMedia({
      video: { width: 9999 },
    });
    const { width, height } = stream.getTracks()[0].getSettings();
    if (!width || !height) return;
    const aspectRatio = width / height;
    const tracks = stream.getTracks();
    tracks.forEach((track) => track.stop());

    if (!videoRef.current) return;

    const constraints: Contraints = {
      audio: true,
      video: {
        facingMode: 'user',
        frameRate: { ideal: 30 },
        aspectRatio:
          aspectRatio > 1.5
            ? videoRef.current.clientWidth / videoRef.current.clientHeight
            : videoRef.current.clientHeight / videoRef.current.clientWidth,
      },
    };

    try {
      stream = await navigator.mediaDevices.getUserMedia({
        ...constraints,
        video: {
          ...constraints.video,
          facingMode: isFrontCamera ? 'user' : 'environment',
        },
      });

      audioRef.current = stream.getAudioTracks()[0];

      if ('srcObject' in videoRef.current) {
        videoRef.current.srcObject = stream;
      } else {
        // TODO: type stream parameter
        (videoRef.current as HTMLVideoElement).src = window.URL.createObjectURL(
          stream as any
        );
      }
      console.log(isFrontCamera, isFrontCameraPrev);

      if (isFrontCamera && isFrontCameraPrev !== isFrontCamera) {
        // flipCanvasCtx(ctxRef, canvasDimensionsRef.current);
        (ctxRef.current as CanvasRenderingContext2D).setTransform(
          -1,
          0,
          0,
          1,
          canvasDimensionsRef.current.width,
          0
        );
      }
      videoRef.current.muted = true;
      renderVideoOnCanvas(videoRef.current as HTMLVideoElement);

      videoRef.current.onloadedmetadata = () => {
        (videoRef.current as HTMLVideoElement).play();
      };

      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunksRef.current, {
          // type: "video/webm; codecs=vp9",
          type: 'video/mp4',
        });
        const videoURL = URL.createObjectURL(blob);

        if (!videoRef.current) return;
        videoRef.current.srcObject = null;
        videoRef.current.src = videoURL;
        videoRef.current.play();
        videoRef.current.loop = true;
        videoRef.current.addEventListener('play', () =>
          renderVideoOnCanvas(videoRef.current as HTMLVideoElement)
        );
      };
      mediaRecorderRef.current.ondataavailable = (e) => {
        chunksRef.current = [];
        chunksRef.current.push(e.data);
      };
    } catch (err) {
      alert(err);
    }
  };

  const renderImageOnCanvas = (img: HTMLImageElement, i: number) => {
    if (isFrontCamera) {
      flipCanvasCtx(ctxRef, canvasDimensionsRef.current);
    }
    (ctxRef.current as CanvasRenderingContext2D).drawImage(
      img,
      addedStickers[i].x,
      addedStickers[i].y,
      addedStickers[i].width * addedStickers[i].scale,
      addedStickers[i].height * addedStickers[i].scale
    );
    requestPhotoId.current = requestAnimationFrame(() =>
      renderImageOnCanvas(img, i)
    );
    if (isFrontCamera) {
      flipCanvasCtx(ctxRef, canvasDimensionsRef.current);
    }
  };

  const renderVideoOnCanvas = (video: HTMLVideoElement) => {
    (ctxRef.current as CanvasRenderingContext2D).drawImage(
      video,
      0,
      0,
      canvasDimensionsRef.current.width,
      canvasDimensionsRef.current.height
    );
    requestVideoId.current = requestAnimationFrame(() =>
      renderVideoOnCanvas(video)
    );
  };

  useEffect(() => {
    if (!canvasRef.current) return;
    ctxRef.current = (canvasRef.current as HTMLCanvasElement).getContext('2d');
    getMedia();
  }, []);

  useEffect(() => {
    if (!isInCaptureMode) return;
    console.log('ok');
  }, [isInCaptureMode]);

  useEffect(() => {
    if (isFirstRender) return;
    getMedia();
  }, [isFrontCamera]);

  // const { pathname } = useLocation();
  // useEffect(() => {
  //   console.log(pathname);

  //   setIsInCaptureMode(true);
  //   setIsPhotoShot(false);
  //   setIsVideoRecord(false);
  //   setAddedStickers([]);
  // }, [pathname]);

  const handlePhotoCapture = () => {
    if (isFrontCamera) {
      flipCanvasCtx(ctxRef, canvasDimensionsRef.current);
    }
    (ctxRef.current as CanvasRenderingContext2D).drawImage(
      canvasRef.current as HTMLCanvasElement,
      0,
      0,
      canvasDimensionsRef.current.width,
      canvasDimensionsRef.current.height
    );
    if (isFrontCamera) {
      flipCanvasCtx(ctxRef, canvasDimensionsRef.current);
    }
  };

  useEffect(() => {
    if (!isPhotoShot) {
      if (isFirstRender) return;
      setIsInCaptureMode(true);
      getMedia();
      return clearCanvasCtx(ctxRef, canvasDimensionsRef.current);
    }

    handlePhotoCapture();

    cancelAnimationFrame(requestVideoId.current as number);
    setIsInCaptureMode(false);
  }, [isPhotoShot]);

  useEffect(() => {
    if (isVideoRecording) {
      mediaRecorderRef.current?.start();
    } else {
      mediaRecorderRef.current?.stop();
      if (mediaRecorderRef.current) {
        setIsVideoRecord(true);
      }
    }
  }, [isVideoRecording]);

  useEffect(() => {
    if (!isVideoRecord) {
      if (isFirstRender) return;
      setIsInCaptureMode(true);
      getMedia();
      return clearCanvasCtx(ctxRef, canvasDimensionsRef.current);
    }

    cancelAnimationFrame(requestVideoId.current as number);
    setIsInCaptureMode(false);
  }, [isVideoRecord]);

  const renderStickers = () => {
    return new Promise<number | null>(
      (res: (value: number | null) => void, rej: (reason: any) => void) => {
        addedStickers.forEach((image, i) => {
          const img = new Image();
          img.src = image.src;
          img.onload = () => {
            renderImageOnCanvas(img, i);
            if (i + 1 === addedStickers.length) {
              res(requestPhotoId.current);
            }
          };
        });
        setAddedStickers([]);
      }
    );
  };

  const navigate = useNavigate();

  const renderImgStory = async () => {
    if (addedStickers.length) {
      const requestPhotoId = await renderStickers();
      // cancelAnimationFrame(requestPhotoId as number);
    }

    const a = document.createElement('a');
    const date = new Date().toLocaleString();
    a.download = `myStory-${date}.jpg`;
    const url = (canvasRef.current as HTMLCanvasElement).toDataURL('image/jpg');
    a.href = url;
    setRenderedStoryImgPreview(url);
    navigate('/wishes');
    // a.click();
    // window.URL.revokeObjectURL(url);
    // document.body.removeChild(a);
  };

  const renderVideoStory = async () => {
    const videoDuration =
      (videoRef.current as HTMLVideoElement).duration * 1000;
    // (audioRef.current as MediaStreamTrack).currentTime = 0;
    (videoRef.current as HTMLVideoElement).currentTime = 0;

    if (addedStickers.length) {
      await renderStickers();
    }

    const chunks: Blob[] = [];
    const stream = (canvasRef.current as HTMLCanvasElement).captureStream(30);
    const options = {
      audioBitsPerSecond: 128000,
      videoBitsPerSecond: 2500000,
      mimeType: 'video/webm; codecs=vp9',
    };

    const mediaRecorder = new MediaRecorder(stream, options);
    mediaRecorder.ondataavailable = (e) => {
      chunks.push(e.data);
    };
    mediaRecorder.onstop = function () {
      const blob = new Blob(chunks, {
        type: 'video/webm',
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style.display = 'none';
      a.href = url;
      setRenderedStoryVideoPreview(url);
      navigate('/wishes');
      // a.download = 'test.webm';
      // a.click();
      // document.body.removeChild(a);
      // window.URL.revokeObjectURL(url);
    };

    stream.addTrack(audioRef.current as MediaStreamTrack);

    mediaRecorder.start();
    setTimeout(() => mediaRecorder.stop(), videoDuration);
  };

  useEffect(() => {
    if (!renderMedia) return;
    if (isPhotoShot) {
      renderImgStory();
    }
    if (isVideoRecord) {
      renderVideoStory();
    }
  }, [renderMedia]);

  return (
    <>
      <Video
        ref={videoRef}
        autoPlay
        playsInline
        controls={false}
        isFrontCamera={isFrontCamera}
      />
      <Canvas
        ref={canvasRef}
        width={canvasDimensionsRef.current.width}
        height={canvasDimensionsRef.current.height}
        id="viewport"
        isFrontCamera={isFrontCamera}
      />
      <StickersContainer
        canvasDimensions={canvasDimensionsRef.current}
        handleCanvasClick={() => console.log('click')}
      />
      {/* {(isPhotoShot || isVideoRecord) && (
        <div
          onClick={isPhotoShot ? downloadRenderedStory : render}
          style={{ position: 'absolute', left: '20px', zIndex: '100' }}
        >
          <img src={dowloadIcon} alt="Download Icon" width={20} />
        </div>
      )} */}
    </>
  );
};
