import { ReactNode } from 'react';
import styled from 'styled-components';
import { Heading } from './Heading';

interface HeroSectionProps {
  title?: string;
  children?: ReactNode;
}
const HeroContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(to bottom, #fff 25%, #f2f2f2 100%);
  border-radius: 0px 0px 25px 25px;
  padding: 0px 25px;
`;
const StyledHeading = styled(({ className, children }) => (
  <Heading className={className} tag="h1" size="xl" textAlign="center">
    {children}
  </Heading>
))`
  margin-bottom: 50px;
  letter-spacing: -2px;
  line-height: 0.9;
`;
const HeroImage = styled.div`
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    max-width: 340px;
    height: auto;
  }
`;
export const HeroSection = ({ title, children }: HeroSectionProps) => {
  return (
    <section>
      <HeroContainer>
        <StyledHeading>{title}</StyledHeading>
        <HeroImage>{children}</HeroImage>
      </HeroContainer>
    </section>
  );
};
