import { FC } from 'react';
import styled from 'styled-components';
import switzerlandIcon from '../../../assets/icons/switzerland.png';

interface PhoneNumberPrefixProps {
  phoneNumberPrefix: string;
}

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const StyledImg = styled.img``;
const StyledSpan = styled.span`
  color: rgb(var(--clr-dark));
  font-size: var(--text-m);
`;

export const PhoneNumberPrefix: FC<PhoneNumberPrefixProps> = ({
  phoneNumberPrefix,
}) => {
  return (
    <StyledContainer>
      <StyledImg src={switzerlandIcon} alt="Country flag" />
      <StyledSpan>{phoneNumberPrefix}</StyledSpan>
    </StyledContainer>
  );
};
