import { FC } from 'react';
import styled from 'styled-components';
import leftArrow from '../assets/icons/left-arrow.svg';

interface BackButtonProps {
  onBtnClick: () => void;
}

const StyledButton = styled.button`
  position: fixed;
  z-index: 1;
  top: 25px;
  left: 20px;
  display: grid;
  place-content: center;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  box-shadow: 0 2px 10px 0 var(--shadow);
  background-color: rgb(var(--clr-light));
`;

const StyledImg = styled.img``;

export const BackButton: FC<BackButtonProps> = ({ onBtnClick }) => {
  return (
    <StyledButton onClick={onBtnClick}>
      <StyledImg src={leftArrow} alt="Left arrow" width="9" height="15" />
    </StyledButton>
  );
};
