import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

export const GlobalStyle = createGlobalStyle`
${normalize}

* {
  box-sizing: border-box;
}

:root {
  --font-family: 'Inter', sans-serif;

  --primary: #000000;
  --primary-light: #fff;
  --secondary: rgba(0,0,0,.5);
  --secondary-light: rgba(255,255,255,.5);

  --heading-s: 18px;
  --heading-m: 20px;
  --heading-l: 24px;
  --heading-xl: 50px;

  --text-xs: 12px;
  --text-s: 14px;
  --text-m: 16px;
  --text-l: 20px;

  --text-regular: 400;
  --text-medium: 500;
  --text-bolder: 600;
  --text-bold: 700;

  --clr-light: 255, 255, 255;
  --clr-dark: 0, 0, 0;
  --clr-gray: rgb(238, 238, 239)
  --clr-primary: rgb(var(--clr-dark));
  --clr-primary-light: rgba(var(--clr-dark), 0.4);
  --clr-secondary: 253, 45, 85;

  --shadow: rgb(var(--clr-dark), 0.15)
}

body {
  font-family: var(--font-family);
  font-size: var(--text-s);
  font-weight: var(--txt-medium);
  color: var(--clr-primary);
}

input {
  font-family: var(--font-family);
  font-size: var(--text-s);
  font-weight: var(--txt-medium);
  color: var(--clr-primary);
  &::placeholder {
    color: var(--clr-primary);
  }
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0
}

button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
}

.hideScrollbar {
  -ms-overflow-style: none; 
  scrollbar-width: none;  
  &::-webkit-scrollbar {
    display: none;
  }
}
`;
