import { useEffect, useRef } from 'react';

export const useIsFirstRender = () => {
  const isFirstRender = useRef<boolean>(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
  });

  return isFirstRender.current;
};
