import { useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { StickerData, stickers } from "../../../data/stickers";
import { Sticker } from "./Sticker";
import { MediaCaptureContext } from "../../../context/MediaCaptureContext";
import { Position } from "../../StorySticker";

interface StickersProps {
  inputValue: string;
  activeTab: number;
}

const StickerContainer = styled.div.attrs((props) => ({
  className: props.className,
}))`
  display: grid;
  grid-template-columns: repeat(auto-fill, 90px);
  grid-auto-rows: 95px;
  gap: 30px;
  justify-content: space-around;
  justify-items: center;
  align-content: flex-start;
  align-items: center;
  overflow-y: auto;
  max-height: calc(100% - 40px - 5px - 26px - 44px);
  padding-bottom: 44px;
`;

export const Stickers = ({ inputValue, activeTab }: StickersProps) => {
  const filteredStickersInitialState: [] = [];
  const [filteredStickers, setFilteredStickers] = useState<StickerData[]>(
    filteredStickersInitialState
  );
  const isFilteredStickers = filteredStickers.length !== 0;
  const { setAddedStickers, setIsStickersModalOpened } =
    useContext(MediaCaptureContext);
  const [defaultPosition, setDefaultPosition] = useState<Position>({
    x: 0,
    y: 0,
  });

  useMemo(() => {
    if (inputValue.length > 0) {
      const filteredStickers = stickers.filter(
        ({ id, src, tags, categoriesId }) =>
          tags.some(
            (tag) =>
              tag.slice(0, inputValue.length) === inputValue.toLowerCase()
          )
      );
      setFilteredStickers(filteredStickers);
    } else {
      setFilteredStickers(filteredStickersInitialState);
    }
  }, [inputValue]);

  useEffect(() => {
    const x = window.innerWidth / 2 - 50;
    const y = window.innerHeight / 2 - 75;
    setDefaultPosition({ x, y });
  }, []);

  return (
    <StickerContainer className="hideScrollbar">
      {inputValue
        ? isFilteredStickers
          ? filteredStickers.map(
              (sticker) =>
                sticker.categoriesId.includes(activeTab) && (
                  <Sticker
                    key={sticker.id}
                    id={sticker.id}
                    src={sticker.src}
                    tags={sticker.tags}
                    categoriesId={sticker.categoriesId}
                    onClick={() => {
                      setAddedStickers((stickers) => [
                        ...stickers,
                        {
                          ...sticker,
                          x: defaultPosition.x,
                          y: defaultPosition.y,
                          width: 100,
                          height: 0,
                          scale: 1,
                        },
                      ]);
                      setIsStickersModalOpened(false);
                    }}
                  />
                )
            )
          : null
        : stickers.map(
            (sticker) =>
              sticker.categoriesId.includes(activeTab) && (
                <Sticker
                  key={sticker.id}
                  id={sticker.id}
                  src={sticker.src}
                  tags={sticker.tags}
                  categoriesId={sticker.categoriesId}
                  onClick={() => {
                    setAddedStickers((stickers) => [
                      ...stickers,
                      {
                        ...sticker,
                        x: defaultPosition.x,
                        y: defaultPosition.y,
                        width: 100,
                        height: 0,
                        scale: 1,
                      },
                    ]);
                    setIsStickersModalOpened(false);
                  }}
                />
              )
          )}
    </StickerContainer>
  );
};
