import { FC } from 'react';
import styled from 'styled-components';
import { Navbar } from '../components/Navbar';
import gift from '../assets/img/gift.webp';
import arrow from '../assets/img/arrow.png';
import { Heading } from '../components/Heading';
import { Paragraph } from '../components/Paragraph';
import { Button } from '../components/Button';
import { List } from '../components/List';
import { ListItem } from '../components/ListItem';

const StyledNavbar = styled(({ className }) => (
  <Navbar className={className} />
))`
  margin-bottom: 10px;
`;
const StyledBackground = styled.div`
  background-color: #f2f2f3;
`;
const StyledContainer = styled.div`
  background-color: rgb(var(--clr-light));
`;
const StyledContent = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 375px;
  padding: 25px 40px 50px;
`;
const StyledParagraph = styled(Paragraph)`
  margin-bottom: 25px;
  line-height: 1.33;
  font-size: var(--text-m);
`;
const StyledParagraphLeft = styled(Paragraph)`
  align-self: flex-start;
  margin-bottom: 20px;
`;
const StyledImg = styled.img`
  margin-bottom: 12px;
`;
const StyledImgLeft = styled.img`
  align-self: flex-start;
  margin-bottom: 20px;
`;
const StyledList = styled(({ className, children }) => (
  <List className={className} isWithIcon>
    {children}
  </List>
))`
  margin-bottom: 40px;
`;
const StyledDivider = styled.span`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  font-size: var(--text-xs);
  font-weight: var(--text-bolder);
  color: var(--secondary);
  text-transform: uppercase;
  margin-bottom: 30px;
  &:before,
  &:after {
    content: '';
    flex: 1;
    height: 1px;
    background-color: #bcbfc1;
  }
`;
const StyledButton = styled(Button)`
  margin-bottom: 17px;
`;

export const Success: FC = () => {
  return (
    <StyledBackground>
      <StyledNavbar />
      <StyledContainer>
        <StyledContent>
          <StyledImg src={gift} alt="Gift" />
          <Heading size="l" textAlign="center">
            Geschenk erfolgreich versendet!
          </Heading>
          <StyledParagraph textAlign="center">
            Du kannst deine Geschenke in deinem persönlichen Profil speichern.
          </StyledParagraph>
          <StyledButton buttonType="text">Neues Profil anlegen</StyledButton>
          <StyledImgLeft src={arrow} alt="Arrow" />
          <StyledParagraphLeft size="l" weight="bold">
            Warum?
          </StyledParagraphLeft>
          <StyledList>
            {listData.map((listItem) => (
              <ListItem isWithIcon>{listItem}</ListItem>
            ))}
          </StyledList>
          <StyledDivider>Oder</StyledDivider>
          <StyledParagraph>Ich bin bereits Kunde:</StyledParagraph>
          <Button buttonType="text" mode="secondary">
            Login
          </Button>
        </StyledContent>
      </StyledContainer>
    </StyledBackground>
  );
};

const listData: string[] = [
  'Geschenk erneut versenden falls nicht eingelöst',
  'Benachrichtigung wenn Geschenk eingelöst',
  'Erhalte exklusive Angebote',
];
