interface StickersCategory {
  id: number;
  name: string;
}

export const stickersCategories: StickersCategory[] = [
  { id: 1, name: "Angesagt" },
  { id: 2, name: "Liebe" },
  { id: 3, name: "Geburstag" },
  { id: 4, name: "Baby" },
  { id: 5, name: "Spezial" },
];

export interface StickerData {
  id: number;
  src: string;
  tags: string[];
  categoriesId: number[];
}

export const stickers: StickerData[] = [
  {
    id: 1,
    src: "/stickers/sticker-1.svg",
    tags: ["fresh"],
    categoriesId: [1],
  },
  {
    id: 2,
    src: "/stickers/sticker-2.svg",
    tags: ["love"],
    categoriesId: [2],
  },
  {
    id: 3,
    src: "/stickers/sticker-3.svg",
    tags: ["love", "heart"],
    categoriesId: [1, 2],
  },
  {
    id: 4,
    src: "/stickers/sticker-4.svg",
    tags: ["smile"],
    categoriesId: [1, 2],
  },
  {
    id: 5,
    src: "/stickers/sticker-5.svg",
    tags: ["happy birthday"],
    categoriesId: [1, 2],
  },
  {
    id: 6,
    src: "/stickers/sticker-6.svg",
    tags: ["kiss", "lips"],
    categoriesId: [1, 2],
  },
  {
    id: 7,
    src: "/stickers/sticker-7.svg",
    tags: ["hearts", "love"],
    categoriesId: [1, 2],
  },
  {
    id: 8,
    src: "/stickers/sticker-8.svg",
    tags: ["bang"],
    categoriesId: [1, 2],
  },
  {
    id: 9,
    src: "/stickers/sticker-9.svg",
    tags: ["birds"],
    categoriesId: [1, 2],
  },
  {
    id: 10,
    src: "/stickers/sticker-10.svg",
    tags: ["nice"],
    categoriesId: [1, 2],
  },
  {
    id: 11,
    src: "/stickers/sticker-11.svg",
    tags: ["baloons"],
    categoriesId: [1, 2],
  },
  {
    id: 12,
    src: "/stickers/sticker-12.svg",
    tags: ["crazy"],
    categoriesId: [1, 2],
  },
  {
    id: 13,
    src: "/stickers/sticker-13.svg",
    tags: ["brilliant"],
    categoriesId: [1, 2],
  },
  {
    id: 14,
    src: "/stickers/sticker-14.svg",
    tags: ["love"],
    categoriesId: [1, 2],
  },
  {
    id: 15,
    src: "/stickers/sticker-15.svg",
    tags: ["yeah"],
    categoriesId: [1, 2],
  },
  {
    id: 16,
    src: "/stickers/sticker-16.svg",
    tags: ["great job"],
    categoriesId: [1, 2],
  },
  {
    id: 17,
    src: "/stickers/sticker-17.svg",
    tags: ["star"],
    categoriesId: [1, 2],
  },
  {
    id: 18,
    src: "/stickers/sticker-18.svg",
    tags: ["confetti"],
    categoriesId: [1, 2],
  },
];
