import { FC, ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { BackButton } from '../components/BackButton';
import { Button } from '../components/Button';
import { Heading } from '../components/Heading';
import { InputDate } from '../components/Inputs/InputDate';
import { InputText } from '../components/Inputs/InputText';
import { TextArea } from '../components/Inputs/TextArea';
import { WishesMediaPreview } from '../components/WishesMediaPreview';
import { useMediaCaptureContext } from '../hooks/useMediaCaptureContext';

interface StyledButtonProps {
  className?: string;
  children: ReactNode;
  onBtnClick: () => void;
}

interface OverstyledButtonProps {
  onBtnClick: () => void;
}

const StyledContainer = styled.div`
  padding: 0 20px;
`;
const StyledButton: FC<StyledButtonProps> = ({
  className,
  children,
  onBtnClick,
}) => (
  <Button buttonType="text" className={className} onBtnClick={onBtnClick}>
    {children}
  </Button>
);
const OverstyledButton = styled(StyledButton)<OverstyledButtonProps>`
  margin-bottom: 33px;
`;

export const Wishes: FC = () => {
  const [textAreaValue, setTextAreaValue] = useState<string>(`Lieber Adrian,
ich möchte dir von ganzem Herzen danken dass du immer für mich da bist und wünsche dir zu deinem Geburtstag das Allerbeste! 🍀 Ich hoffe du hast Freude an deinem Geschenk und tolle Musikmomente. 🎶
  
Kuss Stefanie 😘✨🥂`);
  const [textAreaLength, setTextAreaLength] = useState<number>(0);
  const [inputTextValue, setInputTextValue] =
    useState<string>('zB. 079 666 44 22');
  const [inputDateValue, setInputDateValue] = useState<string>('');
  const navigate = useNavigate();
  const {
    setIsInCaptureMode,
    setIsPhotoShot,
    setIsVideoRecord,
    setAddedStickers,
    setRenderedStoryImgPreview,
    setRenderedStoryVideoPreview,
  } = useMediaCaptureContext();

  const clearMediaPreview = () => {
    setIsInCaptureMode(true);
    setIsPhotoShot(false);
    setIsVideoRecord(false);
    setAddedStickers([]);
  };

  const handleBackBtnClick = () => {
    clearMediaPreview();
    setRenderedStoryImgPreview('');
    setRenderedStoryVideoPreview('');
    navigate('/story');
  };

  const handleTextAreaValue = (value: string) => {
    setTextAreaValue(value);
    setTextAreaLength(value.length);
  };

  return (
    <>
      <BackButton onBtnClick={handleBackBtnClick} />
      <WishesMediaPreview />
      <StyledContainer>
        <Heading size="s" weight="bold">
          Persönliche Grussbotschaft
        </Heading>
        <TextArea
          labelText="SMS Nachricht schreiben"
          length={textAreaLength}
          value={textAreaValue}
          onTextAreaChange={(value) => handleTextAreaValue(value)}
        />
        <Heading size="s" weight="bold">
          An welche Nummer möchtest du das Geschenk senden?
        </Heading>
        <InputText
          labelText="Telefonnummer"
          value={inputTextValue}
          onInputTextChange={(value) => setInputTextValue(value)}
          captionText="Wird von Happyappy automatisch versendet."
        />
        <Heading size="s" weight="bold">
          An welchem Datum soll deine Grussbotschaft/Geschenk digital
          eintreffen?
        </Heading>
        <InputDate
          labelText="Datum"
          value={inputDateValue}
          onInputDataChange={(value) => setInputDateValue(value)}
          captionText="Bis zu einem Jahr ab dem heutigen Datum."
        />
        <OverstyledButton onBtnClick={() => navigate('/checkout')}>
          Weiter zu Bezahlung
        </OverstyledButton>
      </StyledContainer>
    </>
  );
};
