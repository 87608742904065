import styled from "styled-components";
import { Paragraph } from "../Paragraph";

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 15px;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #eeeeef;
  margin-top: 20px;
`;
const ProductList = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const SummaryOrder = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const Separator = styled.div`
  width: 100%;
  height: 1px;
  border-top: solid 1px #bcbfc1;
  opacity: 0.6;
  margin: 20px 0px;
`;
const TotalHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 8px;
`;
export const Summary = () => {
  return (
    <SummaryContainer>
      <ProductList>
        <Paragraph size="s">Bose QuietComfort 35 II</Paragraph>
        <Paragraph size="s">CHF 199.00</Paragraph>
      </ProductList>
      <Separator />
      <SummaryOrder>
        <TotalHeader>
          <Paragraph size="l" weight="bold">
            Total
          </Paragraph>
          <Paragraph size="s">inkl. MWSt.</Paragraph>
        </TotalHeader>
        <Paragraph size="l" weight="bold">
          CHF 199.00
        </Paragraph>
      </SummaryOrder>
    </SummaryContainer>
  );
};
