import { ReactNode } from 'react';
import styled from 'styled-components';
import { Button } from './Button';
import rotateCameraIcon from '../assets/icons/rotate-camera.svg';
import closeIcon from '../assets/icons/close-white.svg';
import stickerIcon from '../assets/icons/sticker.svg';
import { RecordButton } from './RecordButton';
import { useMediaCaptureContext } from '../hooks/useMediaCaptureContext';
import { StoryNavigation } from './StoryNavigation';
import { Navigate, useNavigate } from 'react-router-dom';

interface StoryLayoutProps {
  onCameraRotate: () => void;
  children: ReactNode;
}

const TopbarButtons = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  z-index: 1;
`;

const CloseButton = styled.button`
  padding: 11px;
  margin-right: -11px;
`;

const Img = styled.img`
  display: block;
`;

const BottombarContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
`;

const BottombarButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 17px;
`;

const ButtonContainer = styled.div`
  display: block;
  color: #fff;
`;

const Layout = styled.div`
  position: fixed;
  top: 10px;
  bottom: calc(112px + 21px);
  left: 20px;
  right: 20px;
  width: calc(100% - 40px);
`;

export const StoryLayout = ({ onCameraRotate, children }: StoryLayoutProps) => {
  const {
    isInCaptureMode,
    setIsInCaptureMode,
    setIsPhotoShot,
    setIsVideoRecord,
    setIsStickersModalOpened,
    setAddedStickers,
  } = useMediaCaptureContext();

  const clearMediaPreview = () => {
    setIsInCaptureMode(true);
    setIsPhotoShot(false);
    setIsVideoRecord(false);
    setAddedStickers([]);
  };

  const navigate = useNavigate();

  return (
    <Layout>
      <TopbarButtons>
        <CloseButton
          onClick={
            isInCaptureMode ? () => navigate('/product') : clearMediaPreview
          }
        >
          <Img src={closeIcon} alt="Close Icon" width={18} height={18} />
        </CloseButton>
      </TopbarButtons>
      {children}
      <BottombarContainer>
        <BottombarButtons>
          <ButtonContainer>
            <Button
              buttonType={'icon'}
              onBtnClick={() => setIsStickersModalOpened(true)}
            >
              <Img
                src={stickerIcon}
                alt="Sticker Icon"
                width={29}
                height={29}
              />
            </Button>
          </ButtonContainer>
          <RecordButton />
          <ButtonContainer>
            <Button buttonType={'icon'} onBtnClick={onCameraRotate}>
              <Img
                src={rotateCameraIcon}
                alt="Rotate camera Icon"
                width={20}
                height={20}
              />
            </Button>
          </ButtonContainer>
        </BottombarButtons>
        <StoryNavigation />
      </BottombarContainer>
    </Layout>
  );
};
