import { useRef, useState } from 'react';

export const useTouchEventDetection = () => {
  const actionInitialState = '';
  const timerRefInitialValue = null;
  const isLongPressRefInitialValue = false;
  const [action, setAction] = useState<string>(actionInitialState);
  const timerRef = useRef<any>(timerRefInitialValue);
  const isLongPressRef = useRef<boolean>(isLongPressRefInitialValue);

  const resetTouchEventDetectionHookState = () => {
    setAction(actionInitialState);
    timerRef.current = timerRefInitialValue;
    isLongPressRef.current = isLongPressRefInitialValue;
  };

  function startPressTimer() {
    isLongPressRef.current = false;
    timerRef.current = setTimeout(() => {
      isLongPressRef.current = true;
      setAction('longpress');
    }, 100);
  }

  // function handleOnClick() {
  //   console.log('handleOnClick');
  //   if (isLongPress.current) {
  //     console.log('Is long press - not continuing.');
  //     return;
  //   }
  //   setAction('click');
  // }

  // function handleOnMouseDown() {
  //   console.log('handleOnMouseDown');
  //   startPressTimer();
  // }

  // function handleOnMouseUp() {
  //   console.log('handleOnMouseUp');
  //   clearTimeout(timerRef.current);
  // }

  function handleOnTouchStart() {
    // console.log('handleOnTouchStart');

    startPressTimer();
  }

  function handleOnTouchEnd() {
    // if (action === 'longpress') return;

    // console.log('handleOnTouchEnd');
    clearTimeout(timerRef.current);

    // console.log('handleOnClick');
    if (isLongPressRef.current) {
      // console.log('Is long press - not continuing.');
      setAction('longpressend');
      return;
    }
    setAction('click');
  }

  return {
    action,
    resetTouchEventDetectionHookState,
    handlers: {
      // onClick: handleOnClick,
      // onMouseDown: handleOnMouseDown,
      // onMouseUp: handleOnMouseUp,
      onTouchStart: handleOnTouchStart,
      onTouchEnd: handleOnTouchEnd,
    },
  };
};
