import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
type Mode = 'primary' | 'secondary';
type As = 'icon' | 'text';
interface ButtonProps {
  onBtnClick?: () => void;
  children: ReactNode;
  mode?: Mode;
  className?: string;
  buttonType?: As;
}

const CustomerButton = styled.button.attrs(
  (props: { mode: Mode; buttonType: As }) => props
)`
  background: ${(props) =>
    props.mode === 'primary' ? 'rgb(var(--clr-dark))' : 'transparent'};
  color: ${(props) =>
    props.mode === 'primary'
      ? 'rgb(var(--clr-light))'
      : 'rgb(var(--clr-dark))'};
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: var(--text-bolder);
  font-size: var(--text-m);
  ${(props) => {
    if (props.buttonType === 'text') {
      return css`
        width: 100%;
        max-width: 335px;
        border: 1px solid rgb(var(--clr-dark));
        border-radius: 30px;
      `;
    }
    if (props.buttonType === 'icon') {
      return css`
        padding: 10px;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        background-color: rgba(0, 0, 0, 0.5);
      `;
    }
  }};
`;

export const Button = ({
  onBtnClick,
  children,
  mode = 'primary',
  buttonType = 'icon',
  className,
}: ButtonProps) => {
  return (
    <CustomerButton
      onClick={onBtnClick}
      mode={mode}
      className={className}
      buttonType={buttonType}
    >
      {children}
    </CustomerButton>
  );
};
