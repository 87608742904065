import { img, icons } from '../../utils';

const {
  mastercard: mastercardImg,
  visa: visaImg,
  twint: twintImg,
  postfinance: postfinanceImg,
  appStore: appStoreImg,
  googlePlay: googlePlayImg,
  facebook: facebookIcon,
  instagram: instagramIcon,
  youtube: youtubeIcon,
} = { ...img, ...icons } as { [key: string]: any };

export const footerLinks: string[] = [
  'Kontakt',
  'Häufige Fragen',
  'Über Uns',
  'Impressum',
  'Datenschutz',
  "AGB's",
];

interface PaymentMethodsData {
  src: string;
  alt: string;
}

export const paymentMethodsData: PaymentMethodsData[] = [
  {
    src: visaImg,
    alt: 'Visa',
  },
  {
    src: mastercardImg,
    alt: 'Mastercard',
  },
  {
    src: twintImg,
    alt: 'Twint',
  },
  {
    src: postfinanceImg,
    alt: 'Postfinance',
  },
];

interface Data extends PaymentMethodsData {
  href: string;
}

export const storesData: Data[] = [
  { href: '', src: appStoreImg, alt: 'App Store' },
  { href: '', src: googlePlayImg, alt: 'Google Play' },
];

export const socialMediaData: Data[] = [
  { href: '', src: facebookIcon, alt: 'Facebook icon' },
  { href: '', src: instagramIcon, alt: 'Instagram icon' },
  { href: '', src: youtubeIcon, alt: 'YouTube icon' },
];
