import { FC } from 'react';
import styled from 'styled-components';
import { Paragraph } from '../Paragraph';
import { PhoneNumberPrefix } from './components/PhoneNumberPrefix';

interface InputTextProps {
  labelText: string;
  value: string;
  onInputTextChange: (inputTextValue: string) => void;
  captionText: string;
}
const StyledLabel = styled.label`
  display: block;
  color: rgb(var(--clr-dark));
  font-size: var(--text-s);
  margin-bottom: 7px;
`;

const StyledInputTextContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 335px;
  padding: 15px;
  border: solid 1px #bcbfc1;
  border-radius: 10px;
  margin-bottom: 11px;
`;

const StyledLine = styled.div`
  height: 27px;
  border: 1px solid #bcbfc1;
  opacity: 0.5;
  margin: 0 10px 0 12px;
`;

const StyledInputText = styled.input`
  width: inherit;
  border: none;
  color: rgb(var(--clr-dark));
  font-size: var(--text-m);
  flex: 1;
  outline: none;
`;

const StyledCaption = styled(({ className, children }) => (
  <Paragraph className={className} size="xs" color="secondary" weight="bolder">
    {children}
  </Paragraph>
))`
  margin-bottom: 40px;
`;

export const InputText: FC<InputTextProps> = ({
  labelText,
  value,
  onInputTextChange,
  captionText,
}) => {
  return (
    <>
      <StyledLabel htmlFor="input">{labelText}</StyledLabel>
      <StyledInputTextContainer>
        <PhoneNumberPrefix phoneNumberPrefix="+41" />
        <StyledLine />
        <StyledInputText
          id="input"
          type="text"
          value={value}
          onChange={(e) => onInputTextChange(e.target.value)}
        />
      </StyledInputTextContainer>

      <StyledCaption>{captionText}</StyledCaption>
    </>
  );
};
