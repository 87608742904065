import { render } from '@testing-library/react';
import { FC, ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../components/Button';
import { MediaCapture } from '../components/MediaCapture';
import { StickersModal } from '../components/StickersModal/StickersModal';
import { StoryLayout } from '../components/StoryLayout';
import { MediaCaptureContextProvider } from '../context/MediaCaptureContext';

interface StyledButtonProps {
  className?: string;
  children: ReactNode;
  onBtnClick: () => void;
}

interface OverstyledButtonProps {
  onBtnClick: () => void;
}

const StyledButton: FC<StyledButtonProps> = ({
  className,
  children,
  onBtnClick,
}) => (
  <Button buttonType="text" className={className} onBtnClick={onBtnClick}>
    {children}
  </Button>
);

const OverstyledButton = styled(StyledButton)<OverstyledButtonProps>`
  position: fixed;
  bottom: 32px;
  left: 50%;
  translate: -50% 0;
`;

export const Story = () => {
  const [isFrontCamera, setIsFrontCamera] = useState<boolean>(true);

  const [btnClicked, setBtnClicked] = useState(false);
  const handleBtnClick = () => {
    setBtnClicked(true);
    setTimeout(() => setBtnClicked(false), 100);
  };

  return (
    // <MediaCaptureContextProvider>
    <>
      <StoryLayout onCameraRotate={() => setIsFrontCamera(!isFrontCamera)}>
        <MediaCapture isFrontCamera={isFrontCamera} renderMedia={btnClicked} />
      </StoryLayout>
      <OverstyledButton onBtnClick={handleBtnClick}>Weiter</OverstyledButton>
      <StickersModal />
    </>
    // </MediaCaptureContextProvider>
  );
};
