import { FC, ReactNode } from 'react';
import styled from 'styled-components';

interface ListItemProps {
  isWithIcon?: boolean;
  children: ReactNode;
}

const StyledListItem = styled.li`
  font-size: var(--text-m);
  line-height: 1.33;
  &:not(:last-child) {
    margin-bottom: 14px;
  }
`;

const StyledSpan = styled.span`
  position: relative;
  left: calc(14px - 7px);
`;

export const ListItem: FC<ListItemProps> = ({
  isWithIcon = false,
  children,
}) => {
  return (
    <StyledListItem>
      {isWithIcon ? <StyledSpan>{children}</StyledSpan> : children}
    </StyledListItem>
  );
};
