import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
  useRef,
  MutableRefObject,
} from 'react';
import { StickerData } from '../data/stickers';

interface IMediaCaptureContext {
  isInCaptureMode: boolean;
  setIsInCaptureMode: Dispatch<SetStateAction<boolean>>;
  isPhotoShot: boolean;
  setIsPhotoShot: Dispatch<SetStateAction<boolean>>;
  isVideoRecord: boolean;
  setIsVideoRecord: Dispatch<SetStateAction<boolean>>;
  isVideoRecording: boolean;
  setIsVideoRecording: Dispatch<SetStateAction<boolean>>;
  addedStickers: AddedSticker[] | [];
  setAddedStickers: Dispatch<SetStateAction<AddedSticker[] | []>>;
  isStickersModalOpened: boolean;
  setIsStickersModalOpened: Dispatch<SetStateAction<boolean>>;
  renderedStoryImgPreview: string;
  setRenderedStoryImgPreview: Dispatch<SetStateAction<string>>;
  renderedStoryVideoPreview: string;
  setRenderedStoryVideoPreview: Dispatch<SetStateAction<string>>;
}

interface MediaCaptureContextProviderProps {
  children: ReactNode;
}

export interface AddedSticker extends StickerData {
  x: number;
  y: number;
  width: number;
  height: number;
  scale: number;
}

export const MediaCaptureContext = createContext({
  isInCaptureMode: false,
  setIsInCaptureMode: () => {},
  isPhotoShot: false,
  setIsPhotoShot: () => {},
  isVideoRecord: false,
  setIsVideoRecord: () => {},
  isVideoRecording: false,
  setIsVideoRecording: () => {},
  addedStickers: [],
  setAddedStickers: () => {},
  isStickersModalOpened: false,
  setIsStickersModalOpened: () => {},
  renderedStoryImgPreview: '',
  setRenderedStoryImgPreview: () => {},
  renderedStoryVideoPreview: '',
  setRenderedStoryVideoPreview: () => {},
} as IMediaCaptureContext);

export const MediaCaptureContextProvider = ({
  children,
}: MediaCaptureContextProviderProps) => {
  const [isInCaptureMode, setIsInCaptureMode] = useState<boolean>(true);
  const [isPhotoShot, setIsPhotoShot] = useState<boolean>(false);
  const [isVideoRecord, setIsVideoRecord] = useState<boolean>(false);
  const [isVideoRecording, setIsVideoRecording] = useState<boolean>(false);
  const [addedStickers, setAddedStickers] = useState<AddedSticker[] | []>([]);
  const [isStickersModalOpened, setIsStickersModalOpened] =
    useState<boolean>(false);
  const [renderedStoryImgPreview, setRenderedStoryImgPreview] =
    useState<string>('');
  const [renderedStoryVideoPreview, setRenderedStoryVideoPreview] =
    useState<string>('');

  const value = {
    isInCaptureMode,
    setIsInCaptureMode,
    isPhotoShot,
    setIsPhotoShot,
    isVideoRecord,
    setIsVideoRecord,
    isVideoRecording,
    setIsVideoRecording,
    addedStickers,
    setAddedStickers,
    isStickersModalOpened,
    setIsStickersModalOpened,
    renderedStoryImgPreview,
    setRenderedStoryImgPreview,
    renderedStoryVideoPreview,
    setRenderedStoryVideoPreview,
  };

  return (
    <MediaCaptureContext.Provider value={value}>
      {children}
    </MediaCaptureContext.Provider>
  );
};
