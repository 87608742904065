import { FC } from 'react';
import { Footer } from '../components/Footer/Footer';
import { HeroSection } from '../components/HeroSection';
import { ItemsCarousel } from '../components/ItemsCarousel';
import { Navbar } from '../components/Navbar';
import HomeSlide from '../assets/img/heroSlide.png';

import BrackLogo from '../assets/img/Partners/logo_brack.png';
import OchsnerLogo from '../assets/img/Partners/logo_ochsner.png';
import OrelifSSliLogo from '../assets/img/Partners/logo_orell.png';
import { SingleProduct } from '../components/SingleProduct';
import { Heading } from '../components/Heading';
import styled from 'styled-components';

export const Home: FC = () => {
  const partnersLogos = [
    { src: BrackLogo },
    { src: OchsnerLogo },
    { src: OrelifSSliLogo },
  ];
  const StyledNavbar = styled(Navbar)`
    margin-bottom: 30px;
  `;
  const Wrapper = styled.div`
    padding: 0 20px;
  `;
  return (
    <>
      <StyledNavbar />
      <HeroSection title="Geschenke online verschenken">
        <img src={HomeSlide} alt="Hero" />
      </HeroSection>
      <ItemsCarousel title="Unsere Geschenke Partner:" items={partnersLogos} />
      <Wrapper>
        <Heading size="l" tag="h2">
          Geschenk Klickproto
        </Heading>
        <SingleProduct />
      </Wrapper>
      <Footer />
    </>
  );
};
