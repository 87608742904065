import { FC } from 'react';
import styled from 'styled-components';

interface DropdownProps {
  activeOption: string;
}

const StyledContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 25px;
  background-color: rgba(var(--clr-light), 0.1);
  padding: 17px 28px;
  font-weight: var(--text-medium);
  color: rgb(var(--clr-light));
`;
const StyledArrowDown = styled.div`
  border: solid rgb(var(--clr-light));
  border-width: 0 2px 2px 0;
  padding: 4px;
  rotate: 45deg;
  translate: 0 -3px;
`;

export const Dropdown: FC<DropdownProps> = ({ activeOption }) => {
  return (
    <StyledContainer>
      {activeOption}
      <StyledArrowDown />
    </StyledContainer>
  );
};
