import { FC } from 'react';
import { TextareaHTMLAttributes } from 'react';
import styled from 'styled-components';

interface TextAreaProps {
  labelText: string;
  length: number;
  value: string;
  onTextAreaChange: (value: string) => void;
}

interface StyledTextAreaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  maxLength: number;
}

const StyledLabel = styled.label`
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
  color: rgb(var(--clr-dark));
  font-size: var(--text-s);
`;

const StyledCounter = styled.span`
  color: var(--clr-primary);
  font-size: var(--text-xs);
`;

const StyledTextArea = styled.textarea<StyledTextAreaProps>`
  border: solid 1px #bcbfc1;
  border-radius: 10px;
  padding: 15px;
  font-size: var(--text-m);
  line-height: 1.38;
  width: 100%;
  max-width: 335px;
  height: 228px;
  resize: none;
  outline: none;
  margin-bottom: 33px;
`;

export const TextArea: FC<TextAreaProps> = ({
  labelText,
  length,
  value,
  onTextAreaChange,
}) => {
  return (
    <>
      <StyledLabel htmlFor="textarea">
        {labelText}
        <StyledCounter>{`${length}/100`}</StyledCounter>
      </StyledLabel>
      <StyledTextArea
        id="textarea"
        maxLength={100}
        value={value}
        onChange={(e) => onTextAreaChange(e.target.value)}
      />
    </>
  );
};
