import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface GalleryLabelProps {
  galleryDimensions: number;
  galleryMarginRight: number;
}

interface StyledNavigationContainerProps {
  navigationContainerMargin: number;
}

const GALLERY_DIMENSIONS = 36;
const GALLERY_MARGIN_RIGHT = 31;
const NAVIGATION_CONTAINER_MARGIN_LEFT = -(
  GALLERY_DIMENSIONS + GALLERY_MARGIN_RIGHT
);

const Navigation = styled.nav.attrs((props) => ({
  className: props.className,
}))`
  display: flex;
  align-items: center;
  margin: 0 8px;
`;
const GalleryLabel = styled.label<GalleryLabelProps>`
  width: ${({ galleryDimensions }) => galleryDimensions}px;
  height: ${({ galleryDimensions }) => galleryDimensions}px;
  border-radius: 8px;
  border: 2px solid rgb(var(--clr-light));
  margin-right: ${({ galleryMarginRight }) => galleryMarginRight}px;
`;
const GalleryInput = styled.input`
  display: none;
`;
const StyledNavigationContainer = styled.div<StyledNavigationContainerProps>`
  overflow-x: auto;
  flex: 1;
  display: flex;
  scroll-snap-type: x mandatory;
  margin-left: ${({ navigationContainerMargin }) =>
    navigationContainerMargin}px;
  &:before,
  &:after {
    content: '';
    min-width: 100vw;
  }
`;
const NavigationLink = styled.button.attrs(
  (props: { isActive: boolean }) => props
)`
  text-decoration: none;
  color: ${({ isActive }) =>
    isActive ? 'rgb(var(--clr-light))' : 'rgba(var(--clr-light), 0.5)'};
  padding: 10px 9px;
  position: relative;
  font-size: var(--text-s);
  font-weight: ${({ isActive }) =>
    isActive ? 'var(--text-bold)' : 'var(--text-bolder)'};
  /* min-width: 90px; */
  text-align: center;
  border: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  scroll-snap-align: center;
`;

export const StoryNavigation = () => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleFileUpload = (e: any) => {
    console.log(e);
  };
  const [activeItem, setActiveItem] = useState<number>(1);

  useEffect(() => {
    const element = document.getElementById('activeItem');
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        inline: 'center',
      });
    }
  }, [activeItem]);

  const handleNavigationScroll = (e: any) => {
    setTimeout(() => {
      const clientWidth = window.innerWidth;
      const containerWidth = e.target.clientWidth;
      const containerScrollLeft = e.target.scrollLeft;
      let accumulator = 0;
      const calculatedScrollsLeft = [...e.target.children].map((child) => {
        const calculatedScrollLeft =
          clientWidth -
          containerWidth / 2 +
          accumulator +
          Math.ceil(child.clientWidth / 2);
        accumulator += child.clientWidth;
        return Math.floor(calculatedScrollLeft);
      });
      console.log(containerScrollLeft, calculatedScrollsLeft);

      setActiveItem(calculatedScrollsLeft.indexOf(containerScrollLeft));
    }, 550);
  };

  return (
    <Navigation>
      <GalleryLabel
        htmlFor="input"
        galleryDimensions={GALLERY_DIMENSIONS}
        galleryMarginRight={GALLERY_MARGIN_RIGHT}
      />
      <GalleryInput
        id="input"
        type="file"
        accept="image/*, video/*"
        onChange={(e: any) => handleFileUpload(e)}
        ref={inputRef}
      />
      <StyledNavigationContainer
        className="hideScrollbar"
        navigationContainerMargin={NAVIGATION_CONTAINER_MARGIN_LEFT}
        onScroll={handleNavigationScroll}
      >
        {navigation.map((item, i) => {
          const isActive = i === activeItem;
          return (
            <NavigationLink
              onClick={() => setActiveItem(i)}
              isActive={isActive}
              id={isActive ? 'activeItem' : 'item'}
              key={item}
            >
              {item}
            </NavigationLink>
          );
        })}
      </StyledNavigationContainer>
    </Navigation>
  );
};

const navigation: string[] = ['Foto', 'Video', 'Vorlagen'];
