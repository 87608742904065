import { useContext, useEffect, useRef, useState } from "react";
import { Image as KonvaImage, Layer, Stage } from "react-konva";
import styled from "styled-components";
import {
  AddedSticker,
  MediaCaptureContext,
} from "../context/MediaCaptureContext";
import { Dimensions } from "./MediaCapture";
import { Position, StorySticker } from "./StorySticker";
import trashIcon from "../assets/icons/trash.svg";
const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
`;
const DeleteContainer = styled.div.attrs(
  (props: { isStickerInDeleteContainer: boolean }) => props
)`
  position: absolute;
  z-index: 100;
  bottom: 30px;
  border: ${(props) =>
    props.isStickerInDeleteContainer
      ? "4px solid rgb(var(--clr-secondary))"
      : " 2px solid white"};
  background: ${(props) =>
    props.isStickerInDeleteContainer
      ? "rgb(var(--clr-secondary))"
      : " transparent"};
  border-radius: 50%;
  padding: 5px;
  left: 45vw;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface StickersContainerProps {
  canvasDimensions?: Dimensions | null;
  handleCanvasClick: () => void;
}

interface Sticker extends AddedSticker {
  index?: number;
}

export const StickersContainer = ({
  canvasDimensions,
  handleCanvasClick,
}: StickersContainerProps) => {
  const { addedStickers, setAddedStickers } = useContext(MediaCaptureContext);
  const deleteSrickerRef = useRef<HTMLDivElement>(null);
  const [isStickerDrag, setIsStickerDrag] = useState<boolean>(false);
  const [dragSticker, setDragSticker] = useState<Sticker | undefined>(
    undefined
  );
  const [isStickerInDeleteContainer, setIsStickerInDeleteContainer] =
    useState<boolean>(false);
  const [deleteContainerPosition, setDeleteContainerPosition] =
    useState<Position>({
      x: 0,
      y: 0,
    });
  const [scale, setScale] = useState<number>(0);

  const removeSticker = (deletedSticker: Sticker) => {
    setAddedStickers((stickers) =>
      stickers.filter((sticker, i) => i !== deletedSticker.index)
    );
  };

  useEffect(() => {
    if (!deleteSrickerRef.current) return;
    setDeleteContainerPosition({
      x: deleteSrickerRef.current.offsetLeft,
      y: deleteSrickerRef.current.offsetTop,
    });
  }, [isStickerDrag]);

  useEffect(() => {
    if (!isStickerDrag && dragSticker && isStickerInDeleteContainer) {
      removeSticker(dragSticker);
    }
    if (!deleteSrickerRef.current) return;
    if (dragSticker) {
      const isX =
        (dragSticker.x &&
          dragSticker.x < deleteContainerPosition.x - 100 * scale) ||
        (dragSticker.x &&
          dragSticker.x > deleteContainerPosition.x + 25 * scale);
      const isY =
        dragSticker.y && dragSticker.y < deleteContainerPosition.y - 50 * scale;
      setIsStickerInDeleteContainer(!isX && !isY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isStickerDrag,
    dragSticker,
    deleteContainerPosition,
    isStickerInDeleteContainer,
  ]);

  if (!addedStickers.length) return null;
  return (
    <Container>
      {canvasDimensions && (
        <Stage
          width={canvasDimensions.width}
          height={canvasDimensions.height}
          onClick={handleCanvasClick}
          onTap={handleCanvasClick}
        >
          <Layer>
            <KonvaImage
              image={undefined}
              height={canvasDimensions.height}
              width={canvasDimensions.width}
              id="backgroundImage"
            />
            {addedStickers &&
              addedStickers.map((sticker, i) => {
                return (
                  <StorySticker
                    onDragEnd={(event) => {
                      sticker.x = event.target.x();
                      sticker.y = event.target.y();
                      setIsStickerDrag(false);
                    }}
                    key={i}
                    sticker={sticker}
                    onDragStart={() => {
                      setIsStickerDrag(true);
                      setDragSticker({ ...sticker, index: i });
                    }}
                    onDragMove={(event) => {
                      sticker.x = event.target.x();
                      sticker.y = event.target.y();
                      setDragSticker({
                        ...sticker,
                        index: i,
                        x: event.target.x(),
                        y: event.target.y(),
                      });
                    }}
                    handleSticker={(scale, height) => {
                      setScale(scale);
                      setDragSticker({
                        ...sticker,
                        scale: scale,
                        height: height,
                      });
                      setAddedStickers((stickers) => {
                        const newStickers = stickers.map((sticker, index) => {
                          if (index === i) {
                            return {
                              ...sticker,
                              scale: scale,
                              height: height,
                            };
                          }
                          return sticker;
                        });
                        return newStickers;
                      });
                    }}
                  />
                );
              })}
          </Layer>
        </Stage>
      )}
      {isStickerDrag && (
        <DeleteContainer
          ref={deleteSrickerRef}
          isStickerInDeleteContainer={isStickerInDeleteContainer}
        >
          <img src={trashIcon} alt="Delete Icon" />
        </DeleteContainer>
      )}
    </Container>
  );
};
