import styled from 'styled-components';
import { SearchBox } from './components/SearchBox';
import closeGrey from '../../assets/icons/close-grey.svg';
import { Tabs } from './components/Tabs';
import { Stickers } from './components/Stickers';
import { useContext, useState } from 'react';
import { MediaCaptureContext } from '../../context/MediaCaptureContext';

interface ModalConatainerProps {
  isStickersModalOpened: boolean;
}

const ModalContainer = styled.div<ModalConatainerProps>`
  position: fixed;
  inset: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(15px);
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding: 20px;

  transform: ${(props) =>
    props.isStickersModalOpened ? 'translateY(0)' : 'translateY(100%)'};
  -o-transform: ${(props) =>
    props.isStickersModalOpened ? 'translateY(0)' : 'translateY(100%)'};
  -moz-transform: ${(props) =>
    props.isStickersModalOpened ? 'translateY(0)' : 'translateY(100%)'};
  -webkit-transform: ${(props) =>
    props.isStickersModalOpened ? 'translateY(0)' : 'translateY(100%)'};

  transition: transform 0.3s ease;
  -o-transition: transform 0.3s ease;
  -moz-transition: transform 0.3s ease;
  -webkit-transition: -webkit-transform 0.3s ease;
`;

const SearchBoxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
`;

const Button = styled.button`
  border: none;
  background-color: transparent;
  padding: 13px;
  margin-right: -13px;
`;

const Img = styled.img`
  display: block;
`;

export const StickersModal = () => {
  const [inputValue, setInputValue] = useState<string>('');
  const [activeTab, setActiveTab] = useState<number>(1);
  const { isStickersModalOpened, setIsStickersModalOpened } =
    useContext(MediaCaptureContext);

  return (
    <ModalContainer isStickersModalOpened={isStickersModalOpened}>
      <SearchBoxContainer>
        <SearchBox
          inputValue={inputValue}
          onInputChange={(inputValue) => setInputValue(inputValue.trim())}
        />
        <Button onClick={() => setIsStickersModalOpened(false)}>
          <Img src={closeGrey} alt="Close icon" width={18} height={18} />
        </Button>
      </SearchBoxContainer>
      <Tabs
        activeTab={activeTab}
        onTabClick={(clickedTab) => setActiveTab(clickedTab)}
      />
      <Stickers inputValue={inputValue} activeTab={activeTab} />
    </ModalContainer>
  );
};
