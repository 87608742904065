import React, { FC } from 'react';
import { Paragraph } from './Paragraph';
import productImg from '../assets/img/product1.jpg';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
interface SingleProductProps {
  id?: number;
}
const SummaryContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 40px;
`;
const Price = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  p {
    padding-right: 13px;
  }
`;
const ProductGallery = styled.div`
  position: relative;
  border-radius: 20px;
  padding: 30px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
  &:before {
    position: absolute;
    inset: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0);
    opacity: 0.05;
    z-index: -1;
  }
  img {
    width: 100%;
    height: auto;
    max-height: 500px;
    object-fit: contain;
  }
  p {
    position: absolute;
    bottom: 10px;
    right: 15px;
  }
`;
export const SingleProduct: FC<SingleProductProps> = ({ id }) => {
  const navigate = useNavigate();

  return (
    <>
      <ProductGallery onClick={() => navigate('/product')}>
        <img src={productImg} alt="Product Thumbnail" />
      </ProductGallery>
      <SummaryContent>
        <Paragraph size="s" color="secondary" weight="bolder">
          Kopfhörer
        </Paragraph>
        <Paragraph
          size="m"
          weight="bolder"
          onClick={() => navigate('/product')}
        >
          Bose QuietComfort 35 II
        </Paragraph>
        <Price>
          <Paragraph size="m" weight="regular">
            CHF 199.–
          </Paragraph>
          <Paragraph size="xs" color="secondary" weight="regular">
            inkl. MwSt.
          </Paragraph>
        </Price>
      </SummaryContent>
    </>
  );
};
