import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { slugify } from '../../utils';
import { Dropdown } from '../Dropdown';
import { Logo } from '../Icons/Logo';
import { List } from '../List';
import { ListItem } from '../ListItem';
import { Paragraph } from '../Paragraph';
import {
  footerLinks,
  paymentMethodsData,
  socialMediaData,
  storesData,
} from './data';

const StyledContainer = styled.div`
  padding: 50px 25px;
  background-color: #16161a;
`;
const StyledLogo = styled(({ className }) => (
  <Logo className={className} isLight />
))`
  margin-bottom: 50px;
`;
const StyledList = styled(({ className, children }) => (
  <List className={className}>{children}</List>
))`
  margin-bottom: 50px;
`;
const StyledLink = styled(({ to, className, children }) => (
  <Link to={to} className={className}>
    {children}
  </Link>
))`
  color: rgb(var(--clr-light));
  text-decoration: none;
`;
const StyledPaymentMethodsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 17px;
  margin-bottom: 50px;
`;
const StyledPaymentMethodsContainerInner = styled(
  StyledPaymentMethodsContainer
)`
  margin-bottom: 0;
`;
const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 50px;
`;
const StyledStoresContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 52px;
`;
const StyledSocialsContainer = styled(StyledStoresContainer)`
  gap: 35px;
`;

export const Footer: FC = () => {
  return (
    <StyledContainer>
      <StyledLogo />
      <StyledList>
        {footerLinks.map((footerLink) => (
          <ListItem>
            <StyledLink to={slugify(footerLink)}>{footerLink}</StyledLink>
          </ListItem>
        ))}
      </StyledList>
      <StyledPaymentMethodsContainer>
        <Paragraph
          size="s"
          weight="regular"
          color="secondary-light"
          lineHeight="1.5"
        >
          Einfach
          <br />
          bezahlen mit
        </Paragraph>
        <StyledPaymentMethodsContainerInner>
          {paymentMethodsData.map(({ src, alt }) => (
            <img src={src} alt={alt} width="33" height="21" />
          ))}
        </StyledPaymentMethodsContainerInner>
      </StyledPaymentMethodsContainer>
      <DropdownContainer>
        <Dropdown activeOption="CHF Schweizer Franken" />
        <Dropdown activeOption="Deutsch" />
      </DropdownContainer>
      <StyledStoresContainer>
        {storesData.map(({ href, src, alt }) => (
          <a href={href}>
            <img src={src} alt={alt} />
          </a>
        ))}
      </StyledStoresContainer>
      <StyledSocialsContainer>
        {socialMediaData.map(({ href, src, alt }) => (
          <a href={href}>
            <img src={src} alt={alt} />
          </a>
        ))}
      </StyledSocialsContainer>
      <Paragraph
        size="s"
        weight="medium"
        color="secondary-light"
        textAlign="center"
      >
        © 2022 Happyappy. All rights reserved.
      </Paragraph>
    </StyledContainer>
  );
};
